import * as React from "react";
import { useState } from "react";

import "./styles/RegisterWebinarFormStyles.css";
import { verifyEmail } from "utils/validations";
import { TextInput } from "components";
import Model from "hooks/Model";
import { databaseService } from "utils/firebase";
import * as moment from "moment";

function RegisterWebinarFormScreen() {
  const [email, setEmail] = useState("");

  const onRegisterEmail = async (email: string) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const currentTime = moment().format("x");

      // TODO: Send to server request to validate duplicate

      await databaseService.saveDatabase(
        `email_subscription/initial_webinar/${currentTime}`,
        {
          email,
          created: currentTime,
        }
      );

      setEmail("");
      Model.updateAlerts({
        message:
          "Hemos registrado tu correo éxistosamente, pronto te enviaremos un correo con los detalles de la sesión informativa.",
        variant: "success",
      });
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSubmit = () => {
    const formatedEmail = email.toLocaleLowerCase().replace(/\s/g, "");

    if (!email || !verifyEmail(formatedEmail)) {
      Model.updateAlerts({
        message: "Ingresa un correo válido para continuar",
        variant: "error",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: "Antes de continuar ...",
        text: `¿Aceptas que utilicemos tu correo electrónico (${formatedEmail}) para compartirte información de valor sobre nuestra próxima "Sesión informativa en vivo" y los detalles del proyecto FROIN? Puedes darte de baja en cualquier momento.`,
        txtLeft: "Cancelar",
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: "Acepto",
        fnRight: () => onRegisterEmail(formatedEmail),
      });
    }
  };

  return (
    <div className="register-container">
      <h3 className="register-title">Sesión informativa en vivo</h3>
      <p className="register-description">
        Vamos abrir un espacio para una charla en vivo con toda persona
        interesada en ser parte del proyecto FROIN. Estamos buscando artistas,
        comercios, marcas, clientes, inversionitas, influerces y más, de la
        industria de eventos y entretenimiento que desean ser parte de un
        proyecto revolucionario.
        <br />
        <br />
        En esta charla les presentaremos el detalle de nuestras propuestas, la
        proyección de la empresa y responder a cualquier duda o comentario de la
        comunidad.
        <br />
        <br />
        ¡Esta oportunidad es limitada!
        <br />
        <br />
        Pronto cerraremos las puertas para nuevas alianzas, no esperes más y
        simplemente ingresa tu email para compartirte la información.
      </p>

      <div className="register-row-submit">
        <TextInput
          value={email}
          onChange={setEmail}
          placeholder={"Tu correo"}
        />
        <button className="register-btn-submit" onClick={onSubmit}>
          Inscribirme
        </button>
      </div>
    </div>
  );
}

export default RegisterWebinarFormScreen;
