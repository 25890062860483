import * as React from "react";
import { useTranslation } from "react-i18next";

import Logocrom from "assets/video/Logocrom.gif";
import pageStyles from "./styles/BannerHomeStyles";
import Model from "hooks/Model";

interface BannerHomeScreenProps {
  showButtons?: boolean;
  onPressButton?: (view: string) => void;
}

const BannerHomeScreen = ({
  showButtons = false,
  onPressButton,
}: BannerHomeScreenProps) => {
  const { t } = useTranslation();
  const styles = pageStyles();

  return (
    <>
      <div className={styles.bannerContainer}>
        <div className={styles.columnBannerLeft}>
          <div className={styles.containerImageLogo}>
            <img src={Logocrom} alt="FROIN" className={styles.imageLogo} />
          </div>
          <div className={styles.overlayVideo} />
        </div>
        <div className={styles.columnBanner}>
          <h3 className={styles.titleBanner}>
            {t("pages.home.bannerHome.welcomeTitle")}
          </h3>
          <p className={styles.descriptionBanner}>
            {t("pages.home.bannerHome.welcomeDescription")}
          </p>
          {showButtons ? (
            <></>
          ) : (
            <button
              className={styles.buttonBanner}
              onClick={() => Model.setData("showAuth", true)}
            >
              {t("pages.home.bannerHome.join")}
            </button>
          )}
        </div>
      </div>
      {showButtons ? (
        <div className={styles.rowButtons}>
          <button
            className={styles.buttonOption}
            onClick={() => onPressButton("roles")}
          >
            Quiero unirme
          </button>
          <button
            className={styles.buttonOption}
            onClick={() => onPressButton("benefits")}
          >
            Beneficios
          </button>
          <button
            className={styles.buttonOption}
            onClick={() => onPressButton("register")}
          >
            Inscripción Webinar
          </button>
          {/* <button className={styles.buttonOption} onClick={() => onPressButton('recommended')}>Reproduce y Gana</button> */}
        </div>
      ) : (
        <div className={styles.introDescriptionContainer}>
          {t("pages.home.bannerHome.introDescription")}
        </div>
      )}
    </>
  );
};

export default BannerHomeScreen;
