// @ts-nocheck

import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import CircularProgress from "@mui/material/CircularProgress";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { returnFormatNumber } from "utils/validations";
import { TextInput, MercadoPagoScript } from "..";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";
import pageStyles from "./styles";
import Api from "utils/api";

function BuyButtons({ quantity, onSelectCountry, country, setQuantity }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    state: { infoUser },
  } = useContext(Store);
  const styles = pageStyles();
  const [mercadoPago, setMercadoPago] = useState(null);
  const [showSendLink, setShowSendLink] = useState(false);
  const [linkCrypto, setLinkCrypto] = useState("");
  const [showInputPhone, setShowInputPhone] = useState(false);
  const [phone, setPhone] = useState("");
  const [idPreferenceMp, setIdPreferenceMp] = useState("");
  const [pendingBuys, setPendingBuys] = useState([]);
  const [unsubscribeTimerPending, setUnsubscribeTimerPending] = useState(null);
  const [secondsToRefreshPending, setSecondsToRefreshPending] = useState(60);
  const [orderPayPal, setOrderPayPal] = useState();

  const payNqButtons = [
    { label: "Enviar pago", method: "NQ_PAY", style: "colombia" },
  ];

  let buttons = [
    { label: "🇨🇴 Colombia", method: "colombia", style: "colombia" },
    {
      label: t("components.buyButtons.anotherCountry"),
      method: "international",
      style: "international",
    },
    { label: "🌐 Crypto", method: "crypto", style: "crypto" },
  ];

  if (country === "colombia") {
    buttons = [
      { label: "Mercado Pago", method: "MP", style: "colombia" },
      { label: "Nequi", method: "NQ", style: "colombia" },
    ];
  } else if (country === "international") {
    buttons = [
      { label: "Pay Pal", method: "PP", style: "international" },
      { label: "Cryptomus", method: "CRYPTO", style: "crypto" },
    ];
  } else if (country === "crypto") {
    buttons = [{ label: "Cryptomus", method: "CRYPTO", style: "crypto" }];
  }

  const returnPrice = (option) => {
    const allNumber = parseInt(quantity?.replace(/\D/g, "") || "0") * 0.01;

    if (option === "MP") {
      return `$ ${returnFormatNumber(allNumber * 1.0429 + 0.3, 2)} USD`;
    } else if (option === "PP") {
      return `$ ${returnFormatNumber(allNumber * 1.064 + 0.3, 2)} USD`;
    } else if (
      option === "CRYPTO" ||
      option === "crypto" ||
      option === "international"
    ) {
      return `$ ${returnFormatNumber(allNumber * 1.03, 2)} USD`;
    } else if (
      option === "NQ" ||
      option === "colombia" ||
      option === "NQ_PAY"
    ) {
      return `$ ${returnFormatNumber(
        allNumber + (allNumber * 0.01 > 1.1 ? 1.1 : allNumber * 0.01) * 1.19,
        2
      )} USD`;
    }

    return "Error";
  };

  const runMercadoPagoRequest = (idPreference) => {
    mercadoPago.checkout({
      preference: {
        id: idPreference,
      },
      autoOpen: true,
    });
  };

  const requestBuyTokens = async (method) => {
    try {
      Model.setData("loading", true);
      if (method === "NQ_PAY" && (phone?.length || 0) < 7) {
        throw new Error(t("components.buyButtons.invalidPhoneFormat"));
      }

      const data = await Api.post("wallet/requestLinkBuy", {
        quantity: parseInt(quantity?.replace(/\D/g, "") || "0"),
        method: method,
        phone: phone,
      });

      if (data.ok && data.data?.idPreference) {
        if (method === "MP") {
          runMercadoPagoRequest(data.data?.idPreference);
          setPendingBuys([data.data.idPreference]);
          setIdPreferenceMp(data.data?.idPreference);
        } else if (method === "NQ_PAY") {
          setPendingBuys([data.data.idPreference]);
          setShowSendLink(true);
        } else if (method === "CRYPTO") {
          setLinkCrypto(data.data.url);
          setPendingBuys([data.data.idPreference]);
          setTimeout(() => {
            window.open(data.data.url);
          }, 1000);
        } else if (method === "PP") {
          setOrderPayPal(data.data.idPreference);
          setPendingBuys([data.data.idPreference]);
        } else {
          Model.updateAlerts({
            message: t("components.buyButtons.purchaseNoConfirmed"),
            variant: "error",
          });
        }
      } else {
        Model.updateAlerts({
          message: t("components.buyButtons.purchaseNoConfirmed"),
          variant: "error",
        });
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onBuyMethod = (method) => {
    let labelMethod = "";

    if (
      !quantity ||
      quantity === "0" ||
      parseInt(quantity?.replace(/\D/g, "")) < 1000
    ) {
      return Model.updateAlerts({
        message: t("components.buyButtons.youMustEnter"),
        variant: "error",
      });
    }

    switch (method) {
      case "colombia":
      case "international":
      case "crypto":
        onSelectCountry(method);
        break;
      case "MP":
      case "CRYPTO":
      case "NQ_PAY":
      case "PP":
        requestBuyTokens(method);
        break;
      case "NQ":
        setShowInputPhone(true);
        break;
      case "PA":
        if (method === "NQ") {
          labelMethod = '"Nequi"';
        }
        if (method === "PP") {
          labelMethod = '"Pay Pal"';
        }
        if (method === "CRYPTO") {
          labelMethod = '"Cryptomus"';
        }
        Model.setData("dialog", {
          open: true,
          title: t("components.buyButtons.buyFroin"),
          text: t("components.buyButtons.thankyou", { quantity, labelMethod }),
          txtRight: t("components.buyButtons.gotIt"),
          fnRight: () => Model.setData("dialog", { open: false }),
        });
        break;
      default:
        break;
    }
  };

  const getPendingTransactions = async () => {
    try {
      Model.setData("loading", true);

      const returnPendingTransactionsData = await Api.post(
        "wallet/returnPendingTransactions"
      );
      const pendingData = returnPendingTransactionsData.data || [];

      onSelectCountry("");
      setShowSendLink(false);
      setIdPreferenceMp("");
      setLinkCrypto("");
      setQuantity();
      setPendingBuys([]);

      if (returnPendingTransactionsData.ok && pendingData.length > 0) {
        setQuantity(returnFormatNumber(pendingData?.[0]?.quantity || 0));
        setPendingBuys(pendingData);

        if (returnPendingTransactionsData.data?.[0]?.method === "NQ_PAY") {
          setShowSendLink(true);
          onSelectCountry("colombia");
        } else if (
          returnPendingTransactionsData.data?.[0]?.method === "CRYPTO"
        ) {
          setLinkCrypto(returnPendingTransactionsData.data?.[0]?.url);
          onSelectCountry("crypto");
        } else if (returnPendingTransactionsData.data?.[0]?.method === "PP") {
          setOrderPayPal(returnPendingTransactionsData.data?.[0]?.idPreference);
          onSelectCountry("international");
        } else if (returnPendingTransactionsData.data?.[0]?.method === "MP") {
          onSelectCountry("colombia");
          setIdPreferenceMp(
            returnPendingTransactionsData.data?.[0]?.idPreference
          );
        }
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  useEffect(() => {
    if (pendingBuys.length > 0) {
      clearTimeout(unsubscribeTimerPending);
      setUnsubscribeTimerPending(
        setTimeout(() => {
          if (secondsToRefreshPending <= 0) {
            setSecondsToRefreshPending(60);
            getPendingTransactions();
          }
          setSecondsToRefreshPending((oldValue) => oldValue - 1);
        }, 1000)
      );
    }
    return () => clearTimeout(unsubscribeTimerPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingBuys, secondsToRefreshPending]);

  useEffect(() => {
    if (infoUser.uid) {
      getPendingTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUser.uid]);

  return (
    <>
      <MercadoPagoScript
        onEndLoad={() => {
          setMercadoPago(
            // @ts-ignore
            new window.MercadoPago(
              "TEST-102d6a76-f4b3-49ff-9f85-9540b19dc1ff",
              {
                locale: "es-CO",
              }
            )
          );
        }}
      />
      {showInputPhone && !showSendLink && (
        <TextInput
          value={phone}
          onChange={setPhone}
          maxLength={15}
          placeholder={t("components.buyButtons.enterPhone")}
        />
      )}
      {linkCrypto || showSendLink || idPreferenceMp ? (
        <div className={styles.rowLoading}>
          {(showSendLink || idPreferenceMp || linkCrypto) && (
            <div className={styles.spinnerRowLoading}>
              <CircularProgress />
              <br />
              <span className="timer">
                {secondsToRefreshPending > 59 ? "1" : "0"}:
                {secondsToRefreshPending > 59
                  ? "00"
                  : secondsToRefreshPending > 9
                  ? secondsToRefreshPending
                  : `0${secondsToRefreshPending}`}
              </span>
            </div>
          )}
          <p className={styles.infoRowLoading}>
            <span>{t("components.buyButtons.paymentSent")}</span>
            <br />
            {t(
              `components.buyButtons.${
                showSendLink
                  ? "weSentNotification"
                  : idPreferenceMp
                  ? "weWaitingForPay"
                  : "ifPaymentNoAppear"
              }`,
              { phone }
            )}
          </p>
          {linkCrypto && (
            <a
              href={linkCrypto}
              target="_blank"
              rel="noreferrer"
              className={styles.linkRowLoading}
            >
              {t("components.buyButtons.gotToPay")}
            </a>
          )}
          {idPreferenceMp && (
            <div
              onClick={() => runMercadoPagoRequest(idPreferenceMp)}
              rel="noreferrer"
              className={styles.linkRowLoading}
            >
              {t("components.buyButtons.gotToPay")}
            </div>
          )}
        </div>
      ) : orderPayPal ? (
        <>
          <div className={styles.containerPayPal}>
            <PayPalScriptProvider
              options={{
                clientId:
                  "AfeOSPs0S40T55Den-O97dGrrQtuutb7p1ULfmifTvfIzFPihNWZ4kabT7oHDTbHbr3A1cceRCyN1e6s",
                currency: "USD",
                debug: process.env.NODE_ENV === "development",
              }}
            >
              <PayPalButtons
                style={{
                  layout: "vertical",
                  label: "buynow",
                }}
                createOrder={() => orderPayPal}
                // onCancel={() => setOrderPayPal()}
                // onError={() => setOrderPayPal()}
                onApprove={() => new Promise(resolve => {
                  navigate("/history")
                  resolve()
                })}
              />
            </PayPalScriptProvider>
          </div>
        </>
      ) : (
        <div className={styles.container}>
          {country && (
            <button
              className={[styles.backBuyBtn, country].join(" ")}
              onClick={() => {
                if (showInputPhone) {
                  setShowInputPhone(false);
                  setPhone("");
                } else {
                  onSelectCountry("");
                }
              }}
            >
              <ArrowBack />
            </button>
          )}
          {(showInputPhone ? payNqButtons : buttons).map((data) => (
            <button
              key={data.label}
              className={[styles.buyBtn, data.style].join(" ")}
              onClick={() => onBuyMethod(data.method)}
            >
              {data.label}
              {quantity && (
                <div className={styles.infoPrice}>
                  {returnPrice(data.method)}
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </>
  );
}

export default BuyButtons;
