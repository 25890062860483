import * as React from "react";
import { makeStyles } from "@mui/styles";

import { Layout, ElementsParallax } from "components";
import FeaturesHome from "../home/FeaturesHome";
import BuyHome from "./BuyHome";

function HomeScreen() {
  const styles = pageStyles();

  return (
    <Layout withBg>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <div className={styles.container}>
        <BuyHome />
        <FeaturesHome />
      </div>
    </Layout>
  );
}

export default HomeScreen;

const pageStyles = makeStyles(() => ({
  container: {
    position: "relative",
    zIndex: 1,
  },
}));
