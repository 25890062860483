import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { detectIncognito } from "detectincognitojs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Backdrop } from "@mui/material";
import { createPortal } from "react-dom";

import { verifyEmail, returnFormatNumber } from "utils/validations";
import { authService, databaseService } from "utils/firebase";
import { TextInput, BuyButtons } from "components";
import Logo from "assets/logos/LogoFroinBlanco.png";
import LocalStorage from "utils/localStorage";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";
import "./styles/BuyHomeStyles.css";

function BuyHomeScreen() {
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const navigate = useNavigate();

  const [containerBuyCardRef, setContainerBuyCardRef] = useState(
    document.getElementById("container-buy-card")
  );
  const [showEmail, setShowEmail] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState("");
  const [currentInvest, setCurrentInvest] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [openFloatModal, setOpenFloatModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const onSupport = () => {
    const response = window.confirm(t("pages.home.buyHome.writeUs"));

    if (response) {
      window.open("mailto:help@froin.co");
    }
  };

  const buyCrypto = async () => {
    try {
      Model.setData("loading", true);
      const formatedEmail = email.toLocaleLowerCase().replace(/\s/g, "");

      if (showEmail) {
        if (!formatedEmail) {
          throw new Error(`ERROR: ${t("pages.home.buyHome.enterValidEmail")}`);
        } else if (!verifyEmail(formatedEmail)) {
          throw new Error(`ERROR: ${t("pages.home.buyHome.formatInvalid")}`);
        } else {
          const result = await detectIncognito();

          await authService.sendSignInLinkToEmail(
            formatedEmail.toLocaleLowerCase()
          );
          LocalStorage(result.isPrivate).setItem(
            "emailForSignInCrypto",
            formatedEmail
          );
          setShowVerifyEmail(true);
        }
      } else {
        setShowEmail(true);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const renderContent = () => {
    if (state.infoUser && Object.keys(state.infoUser).length > 0) {
      return (
        <>
          <p className="label-email">
            {t("pages.home.buyHome.helloAgain")}{" "}
            <span onClick={onSupport}>{t("pages.home.buyHome.contactUs")}</span>
            <br />
            <br />
            {t("pages.home.buyHome.currentlyBalance")}
            <br />
            <br />
            <b>{returnFormatNumber(state.balance)} $FROIN</b>
            <br />
            <br />
            {t("pages.home.buyHome.canBuyMore")}
          </p>
          {selectedCountry ? (
            <p className="label-email">
              {t("pages.home.buyHome.youGoingBuy")}
              <br />
              <b>{quantity} $FROIN</b>
            </p>
          ) : (
            <TextInput
              value={quantity}
              onChange={setQuantity}
              placeholder={t("pages.home.buyHome.howMany")}
              maxLength={10}
              format="currency"
            />
          )}
          <BuyButtons
            country={selectedCountry}
            onSelectCountry={setSelectedCountry}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </>
      );
    } else if (showVerifyEmail) {
      return (
        <p className="label-email">
          {t("pages.home.buyHome.checkEmail")} (<span>{email}</span>){" "}
          {t("pages.home.buyHome.andFollow")}
        </p>
      );
    }

    return (
      <>
        {showEmail && (
          <>
            <p className="label-email">{t("pages.home.buyHome.enterEmail")}</p>
            <TextInput
              value={email}
              onChange={setEmail}
              placeholder="jane@example.com"
              type="email"
            />
          </>
        )}
        <button className="btn-buy" onClick={buyCrypto}>
          {showEmail
            ? t("pages.home.buyHome.logIn")
            : t("pages.home.buyHome.buyFroin")}
        </button>
      </>
    );
  };

  const getBalance = async () => {
    try {
      Model.setData("loading", true);

      const generalData = await databaseService.getDatabase("general");

      setCurrentInvest(generalData.val()?.currentInvest || 0);
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      setContainerBuyCardRef(document.getElementById("container-buy-card"));
    }
  };

  const onClickBuyCard = (option) => {
    if (!isMobile) {
      setOpenFloatModal(option);
      setContainerBuyCardRef(
        option ? null : document.getElementById("container-buy-card")
      );
    }
  };

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);

      if (authService.isSignInWithEmailLink(window.location.href)) {
        const result = await detectIncognito();
        let email = LocalStorage(result.isPrivate).getItem(
          "emailForSignInCrypto"
        );

        if (!email) {
          return Model.updateAlerts({
            message: t("pages.home.buyHome.pleaseEnterEmail"),
            variant: "warning",
          });
        }

        const userInfo = await authService.signInWithEmailLink(
          email,
          window.location.href
        );

        LocalStorage(result.isPrivate).removeItem("emailForSignInCrypto");
        Model.updateInfoUser(userInfo.user);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      navigate("/access-account");
    }
  };

  const handleWindowSizeChange = () => {
    const deviceIsMobile = window.innerWidth <= 780;
    setIsMobile(deviceIsMobile);
  };

  const returnBuyCard = () => (
    <div
      className={`card ${openFloatModal && !isMobile ? "float-modal" : ""}`}
      onClick={() => onClickBuyCard(true)}
    >
      <h2 className="subtitle">📈 {t("pages.home.buyHome.investNow")} 📈</h2>
      <div className="info-card">
        <div className="row-quantity">
          <p className="quantity">
            ${returnFormatNumber(currentInvest)} USD <br />
            {t("pages.home.buyHome.invested")}
          </p>
          <p className="quantity right">
            $1'000,000 USD <br />
            {t("pages.home.buyHome.goal")}
          </p>
        </div>

        <div className="path-bar">
          <div
            className="progress-bar"
            style={{ width: `${currentInvest / 500}%` }}
          />
        </div>
        {renderContent()}
        <div style={{ height: openFloatModal ? 60 : 0 }} />
      </div>
    </div>
  );

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (!user && window.location.href.includes("oobCode")) {
        firstLoad();
      }
    });

    getBalance();

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="subcontainer">
          <div className="column">
            <h1 className="title">{t("pages.home.buyHome.investCrypto")}</h1>
            <h3 className="description">
              {t("pages.home.buyHome.ourProject")}
            </h3>
            <div className="row">
              <a
                className="btn-whitepaper"
                href="https://crypto.fravents.com/wp-content/uploads/2023/12/Whitepaper.pdf"
              >
                WHITEPAPER
              </a>
              <a className="btn-video" href="https://crypto.fravents.com/">
                WEBSITE
              </a>
            </div>
            <img src={Logo} alt="FROIN" className="logo" />
          </div>
          <div className="column" id="container-buy-card">
            {createPortal(
              returnBuyCard(),
              openFloatModal || !containerBuyCardRef
                ? document.body
                : containerBuyCardRef
            )}
          </div>
        </div>
      </div>
      {createPortal(
        <Backdrop
          open={openFloatModal}
          onClick={() => onClickBuyCard(false)}
          className="backdrop"
        />,
        document.body
      )}
    </>
  );
}

export default BuyHomeScreen;
